import React, { useEffect } from "react";
import Button from "../../common/Button/Button";
import AOS from "aos";
import "aos/dist/aos.css";

const HeroSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div className="hero-container" data-aos="fade-in">
      <h1>ACTION NOT WORD</h1>
      <p>Investing in The Future of Afghan Youth Through Education</p>
      <div className="hero-btns">
        <div className="hero-btn-group">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            src="about"
          >
            LEARN MORE
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--large"
            src="donate"
          >
            DONATE NOW
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
