import React from "react";
import ReactPlayer from "react-player/youtube";
import myVideo from '../../videos/OHA4Years.mp4'; // Adjust the import path

const LatestProject = () => {
  console.log(myVideo); // This should log the video URL
  return (
    <div className="latestProject" data-aos="fade-up">
      <h1>Latest Project</h1>
      <div>
      <video width="100%" height="100%" controls>
        <source src={myVideo} type="video/mp4" />
      </video>
    </div>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://youtu.be/IHBM_esSWwg"
          controls="true"
          width="100%"
          height="100%"
        />
      </div>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url="https://www.youtube.com/watch?v=RUL1lmTRNV4"
          controls="true"
          width="100%"
          height="100%"
        />
      </div>
    </div>
  );
};

export default LatestProject;
