import React from "react";
import MemberCard from "./MemberCard";
import naseem from "../../images/members/naseem.jpg";
import hamayon from "../../images/members/hamayon.jpg";
import atiq from "../../images/members/atiq.jpg";
import kasem from "../../images/members/kasem.jpg";
import aisha from "../../images/members/aisha.jpg";

const OurTeam = () => {
  return (
    <div className="ourTeam" data-aos="fade-left">
      <h1>Board of Directors</h1>
      <div className="teamPhotos" data-aos="fade-up">
        <div className="card">
          <MemberCard name="Aisha Hakimi" position="Manager" imageURL={aisha} />
        </div>
        <div className="card">
          <MemberCard name="Atiq Rahin" position="Treasurer" imageURL={atiq} />
        </div>
        <div className="card">
          <MemberCard
            name="Hamayon Ibrahim"
            position="Secretary"
            imageURL={hamayon}
          />
        </div>
        <div className="card">
          <MemberCard
            name="Naseem Yar"
            position="Board Member"
            imageURL={naseem}
          />
        </div>
        <div className="card">
          <MemberCard
            name="Kasem Gardezi"
            position="Board Member"
            imageURL={kasem}
          />
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
