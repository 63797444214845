import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./ContactUs.css"; // Ensure you have a CSS file for styling
export const ConnectWithUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with a duration of 1 second
  }, []);

  return (
    <div className="connectWithUs">
      <h1 data-aos="fade-up">Connect With Us</h1>
      <div className="socialSection">
        <div className="socialIcons" data-aos="fade-up" data-aos-delay="100">
          <i className="fab fa-facebook" />
          <a
            href="https://www.facebook.com/groups/674888409856155/"
            target="_blank"
            rel="noreferrer"
          >
            <p>ANW</p>
          </a>
        </div>
        <div className="socialIcons" data-aos="fade-up" data-aos-delay="200">
          <i className="fab fa-instagram" />
          <a
            href="https://www.instagram.com/actionnotword/"
            target="_blank"
            rel="noreferrer"
          >
            <p>@actionnotword</p>
          </a>
        </div>
        <div className="socialIcons" data-aos="fade-up" data-aos-delay="300">
          <i className="fab fa-youtube" />
          <a
            href="https://www.youtube.com/channel/UCnZVAnJHv6trhGgp9x_z-Pw"
            target="_blank"
            rel="noreferrer"
          >
            <p>Action Not Word</p>
          </a>
        </div>
        <div className="socialIcons" data-aos="fade-up" data-aos-delay="400">
          <i className="fad fa-envelope" />
          <a
            href="mailto:actionnotword@gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            <p>actionnotword@gmail.com</p>
          </a>
        </div>
      </div>
      <div className="map" data-aos="fade-up" data-aos-delay="500">
        <iframe
          eslint-disable-next-line
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345093706!2d144.95543131530702!3d-37.817209979751576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf5778b0a85e1b2b3!2sVictoria%20State%20Library%2C%20328%20Swanston%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sus!4v1618970744081!5m2!1sen!2sus"
          title="Our Educational Centers"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};
export default ConnectWithUs;
