import React, { useState } from "react";
import ContributorData from "./ContributorData";

const Contributors = () => {
  const [date, setDate] = useState("2022");
  return (
    <div className="contributors" data-aos="fade-right">
      <div className="contributorsHeader">
        <h1>Our Generous Benefactors:</h1>
        <select value={date} onChange={(e) => setDate(e.target.value)}>
          <option value="2021">2021</option>
          <option value="2022">2022</option>
          <option value="2023">2023</option>
        </select>
      </div>
      <div className="contributorNames" data-aos="fade-up">
        {ContributorData[date].map((name) => {
          return <p key={name}>{name}</p>;
        })}
      </div>
    </div>
  );
};

export default Contributors;
