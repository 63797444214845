import React, { useState } from "react";
import Button from "../../common/Button/Button";
import SadaqatNews1 from "./sadaqatnews1";
import TeachersDayGallery from "./TeachersDayGallery";
import SadaqatDanishGallery from "./SadaqatDanishGallery";
import myVideo from '../../videos/carpet.mp4';
import myVideo1 from '../../videos/futsalnews1.mp4';

import './News.css'; // Import custom CSS for styling

const MakeAnImpact = () => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [isSadaqatNews1Open, setIsSadaqatNews1Open] = useState(false);
  const [isTeachersDayGalleryOpen, setIsTeachersDayGalleryOpen] = useState(false);
  const [isSadaqatDanishGalleryOpen, setIsSadaqatDanishGalleryOpen] = useState(false);

  const openSadaqatNews1 = () => {
      setIsSadaqatNews1Open(true);
  };
  const openTeachersDayGallery = () => {
    setIsTeachersDayGalleryOpen(true);
  };
  const openSadaqatDanishGallery = () => {
    setIsSadaqatDanishGalleryOpen(true);
  };

  const sharePage = () => {
    const shareData = {
      title: "Share the Good News",
      url: "https://actionnotword.org/news",
    };

    if (navigator.share) {
      navigator.share(shareData)
        .then(() => console.log('Page shared successfully!'))
        .catch((error) => console.error('Error sharing:', error));
    } else {
      navigator.clipboard.writeText(shareData.url)
        .then(() => {
          setCopySuccess(true);
          setTimeout(() => setCopySuccess(false), 5000); // Hide message after 5 seconds
        })
        .catch((error) => console.error('Failed to copy URL:', error));
    }
  };

  return (

    <div>
      {isSadaqatNews1Open && (
        <SadaqatNews1 isOpen={isSadaqatNews1Open} setIsOpen={setIsSadaqatNews1Open} />

      )} 

      {isTeachersDayGalleryOpen && (
        <TeachersDayGallery isOpen={isTeachersDayGalleryOpen} setIsOpen={setIsTeachersDayGalleryOpen} />
      )}

      {isSadaqatDanishGalleryOpen && (
        <SadaqatDanishGallery isOpen={isSadaqatDanishGalleryOpen} setIsOpen={setIsSadaqatDanishGalleryOpen} />
      )}

      <div className="makeAnImpact" data-aos="fade-right">
        <h1> A Fun and Educational Field Trip for Sedaqat & Danish Students </h1>
        <div className="donateSection" data-aos="fade-up">
          <p>
          With the combined efforts of our dedicated teachers and supportive parents, the students of Sedaqat
           & Danish Educational Center had the opportunity to embark on an exciting field trip. They visited
            some of Kabul’s most iconic sites: the Kabul Zoo, the National Museum, and the breathtaking Babur Garden.
            <br/>
          <br/>Throughout the day, the students not only explored the wonders of nature and history but also had
           an amazing time filled with fun and laughter. It was an enriching experience for everyone involved,
            as they connected with both their surroundings and each other.
            <br/>
          <br/>Such activities are a vital part of our educational philosophy, where we believe that learning
           extends beyond the classroom. We look forward to organizing more events that combine education,
            exploration, and enjoyment for our students.
          </p>
          <div className="hero-btns">
            <div className="hero-btn-group">
              <Button
                className="btns"
                buttonStyle="btn--primary"
                buttonSize="btn--large"
                onClick={openSadaqatDanishGallery}
              >
                Photos
              </Button>
              <button className="shareButton" onClick={sharePage}>
                  Share this Page
              </button>
              {copySuccess && <div className="copySuccessMessage">Link copied to clipboard!</div>}
            </div>  
          </div>
        </div>
      </div>
    
      <div className="makeAnImpact" data-aos="fade-right">
        <h1> Teachers' Day Celebration at One Hour for Afghanistan </h1>
        <div className="donateSection" data-aos="fade-up">
          <p>
          On this special occasion of Teachers' Day, we gathered at the One Hour for Afghanistan educational center
          to honor the incredible dedication and hard work of our teachers. They are the backbone of our community,
           inspiring and guiding our students every day.<br></br>
           <br/>
           The celebration was filled with heartfelt speeches, gratitude, and appreciation for the efforts of our
            educators who continuously strive to empower the next generation through knowledge and wisdom. We also
             presented appreciation letters to our teachers as a token of recognition for their invaluable contributions
            to our mission.<br></br>
            <br/>
            We are grateful for their passion and commitment to shaping a brighter future for Afghanistan. Thank you, teachers, for being the guiding light in our journey of education and empowerment!
          </p>
          <div className="hero-btns">
            <div className="hero-btn-group">
              <Button
                className="btns"
                buttonStyle="btn--primary"
                buttonSize="btn--large"
                onClick={openTeachersDayGallery}
              >
                Photos
              </Button>
              <button className="shareButton" onClick={sharePage}>
                  Share this Page
              </button>
              {copySuccess && <div className="copySuccessMessage">Link copied to clipboard!</div>}
            </div>  
          </div>
        </div>
      </div>
    
    <div className="makeAnImpact" data-aos="fade-right">
    <h1>Futsal Tournament, organized by ANW</h1>
    <div className="donateSection" data-aos="fade-up">
      <p>
      The Futsal Tournament, organized by Action Not Words (ANW) members, was a competition specifically
       designed for under-14 boys. The event received financial support from ANW members, showcasing their
        commitment to promoting sports and healthy activities among young participants. The tournament likely
         served as a platform for youth engagement, fostering teamwork, discipline, and community spirit among
          the under-14 boys.
      </p>
      <div className="videoContainer">
        <div>
          <video controls>
            <source src={myVideo1} type="video/mp4" />
          </video>
        </div>
      </div>
      <button className="shareButton" onClick={sharePage}>
        Share this Page
      </button>
      {copySuccess && <div className="copySuccessMessage">Link copied to clipboard!</div>}
    </div>

    <div>
      {isSadaqatNews1Open && (
        <SadaqatNews1 isOpen={isSadaqatNews1Open} setIsOpen={setIsSadaqatNews1Open} />
      )} 
      <div className="makeAnImpact" data-aos="fade-right">
        <h1> Certificate Distribution Ceremony at Sedaqat & Danish Education Centers </h1>
        <div className="donateSection" data-aos="fade-up">
          <p>
            On 28 of August 2024, Sedaqat and Danish Education Centers held a ceremony to honor our top students with
            certificates of achievement. The event recognized the hard work and dedication of our best-performing
            students, celebrating their academic success.
            Teachers, parents, and guests attended the ceremony, adding to the significance of the occasion.
            This event highlighted our ongoing commitment to excellence in education and the bright future of our students.
          </p>
          <div className="hero-btns">
            <div className="hero-btn-group">
              <Button
                className="btns"
                buttonStyle="btn--primary"
                buttonSize="btn--large"
                onClick={openSadaqatNews1}
              >
                Photos
              </Button>
              <button className="shareButton" onClick={sharePage}>
                  Share this Page
              </button>
              {copySuccess && <div className="copySuccessMessage">Link copied to clipboard!</div>}
            </div>  
          </div>
        </div>
      </div>
      
      <div className="makeAnImpact" data-aos="fade-right">
      <h1>Carpets & Foam Mats for Four Classrooms of a Girls' School</h1>
      <div className="donateSection" data-aos="fade-up">
        <p>
          Respected Ahmad Shah Fazel, a family member of Nader Jan Omar, generously provided $300 
          to assist deserving schools. As part of my responsibility, I purchased carpets and foam mats
          for four classrooms of a girls' school that previously lacked proper flooring. The students
          are now benefiting from this contribution. I wanted to share this report with you all!
          Special thanks to Ahmad Shah Fazel and Nader Jan Omar for facilitating this support!
        </p>
        <div className="videoContainer">
          <div>
            <video controls>
              <source src={myVideo} type="video/mp4" />
            </video>
          </div>
        </div>
        <button className="shareButton" onClick={sharePage}>
          Share this Page
        </button>
        {copySuccess && <div className="copySuccessMessage">Link copied to clipboard!</div>}
      </div>

      
      <div className="makeAnImpact" data-aos="fade-right"></div>
      <h1>Eid- al- Adha Program</h1>
      <div className="donateSection" data-aos="fade-up">
        <p>
          In celebration of Eid- al- Adha, sweets were distributed among students of Sedaqat education center. In the meantime, students who completed the first level of English language arts class received their certificates of completion. 
          This is awesome and seeing the happiness in children’s faces is priceless. In my opinion, this is the best kind of Qurbani and Huj. 
          Eid Mubarak and Huj and Qurbani qabool!
        </p>
        <div className="hero-btns">
          <div className="hero-btn-group">
            <Button
              className="btns"
              buttonStyle="btn--primary"
              buttonSize="btn--large"
            >
              Photos
            </Button>
            <Button
              className="btns"
              buttonStyle="btn--primary"
              buttonSize="btn--large"
            >
              Videos
            </Button>
          </div>
        </div>
        <button className="shareButton" onClick={sharePage}>
          Share this Page
        </button>
        {copySuccess && <div className="copySuccessMessage">Link copied to clipboard!</div>}
        </div>
        </div>
        </div>  
      </div>
    </div>
  );
};

export default MakeAnImpact;
