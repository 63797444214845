import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../Button/Button";
import "./Navbar.css";


const Navbar = () => {
  const [click, setClick] = useState(false);
  const [activitiesDropdown, setActivitiesDropdown] = useState(false);
  const [projectsDropdown, setProjectsDropdown] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const toggleActivitiesDropdown = () => setActivitiesDropdown(!activitiesDropdown);
  const toggleProjectsDropdown = () => setProjectsDropdown(!projectsDropdown);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <div>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src={"./logo.jpeg"} alt="" />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                Action Not Word
              </Link>
            </li>
            <li className="nav-item" onMouseEnter={toggleActivitiesDropdown} onMouseLeave={toggleActivitiesDropdown}>
              <Link
                to="/activities"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Our Works <i className="nav-item" />
              </Link>
             
            </li>
            <li className="nav-item" onMouseEnter={toggleProjectsDropdown} onMouseLeave={toggleProjectsDropdown}>
              <Link
                to="/projects"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Our Projects <i className="fas fa-caret-down" />
              </Link>
              {projectsDropdown && (
                <ul className="dropdown-menu">
                  <li>
                    <Link
                      to="/oha"
                      className="dropdown-link" 
                      onClick={closeMobileMenu}
                    >
                      One Hour For Afghanistan
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/projects/project2"
                      className="dropdown-link"
                      onClick={closeMobileMenu}
                    >
                      Action Not Word Center
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/sec"
                      className="dropdown-link"
                      onClick={closeMobileMenu}
                    >
                      Sedaqat Education Center
                    </Link>
                  </li>
                </ul>
              )}
            </li>
            <li className="nav-item">
              <Link
                to="/news"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                NewsLetter
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/about" className="nav-links" onClick={closeMobileMenu}>
                About us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/donate"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Donate Now
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/contact-us"
                className="nav-links-mobile"
                onClick={closeMobileMenu}
              >
                Contact Us
              </Link>
            </li>
          </ul>

          {button && (
            <Button buttonStyle="btn--outline" src="contact-us">
              Contact Us
            </Button>
          )}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
