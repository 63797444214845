import React, { useEffect } from "react";
import WhatWeDo from "./WhatWeDo";
import MakeAnImpact from "./MakeAnImpact";
import AOS from "aos";
import "aos/dist/aos.css";
import "./News.css";

function News() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <h1 className="news" data-aos="fade-up">What is New Today?</h1>
      <MakeAnImpact />
      <hr />
      <WhatWeDo />
    </div>
  );
}

export default News;
