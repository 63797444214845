import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Activity.css";

import img1 from "../../images/activities/soccor/img (1).jpg";
import img2 from "../../images/activities/soccor/img (2).jpg";
import img3 from "../../images/activities/soccor/img (3).jpg";
import img4 from "../../images/activities/soccor/img (4).jpg";
import img5 from "../../images/activities/soccor/img (5).jpg";
import img6 from "../../images/activities/soccor/img (6).jpg";
import img7 from "../../images/activities/soccor/img (7).jpg";
import img8 from "../../images/activities/soccor/img (8).jpg";
import img9 from "../../images/activities/soccor/img (9).jpg";
import img10 from "../../images/activities/soccor/img (10).jpg";
import img11 from "../../images/activities/soccor/img (11).jpg";
import img12 from "../../images/activities/soccor/img (12).jpg";
import img13 from "../../images/activities/soccor/img (13).jpg";



const SoccerGallery = ({ isOpen, setIsOpen }) => {
  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    isOpen && (
      <div className="gallery">
        <div className="gallery-modal">
          <div className="close-button" onClick={closeModal}>
            <i class="far fa-times" />
          </div>
          <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
          >
            <div>
              <img src={img1} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img2} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img3} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img4} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img5} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img6} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img7} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img8} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img9} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img10} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img11} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img12} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img13} alt="" className="carousel-image" />
            </div>
          </Carousel>
        </div>
      </div>
    )
  );
};

export default SoccerGallery;
