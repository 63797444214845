import React from "react";

const Foundation = () => {
  return (
    <div className="foundation" data-aos="fade-right">
      <h1>Foundation</h1>
      <p>
        Action Not Word is a 501(c)(3) nonprofit organization that provides humanitarian relief, with a focus on education, to the children of Afghanistan. Formed in 2020, the organization originally comprised a group of Afghan-American classmates who were once forced out of their home as refugees during the invasion of the country by the Soviet Union in the early 1980s. Since leaving, the founding members of the group have always made it a priority to reach out to those who are in need and remain in the place they once called home.
      </p>
    </div>
  );
};

export default Foundation;
