import React from "react";

const WhatWeDo = () => {
  return (
    <div className="whatWeDo" data-aos="fade-left">
      <h1>What Are We Doing?</h1>
      <section>
        <h2>Action Not Words</h2>
        <p>
          Action Not Words lives up to its namesake by directing every cent collected directly
          to children and families in Afghanistan. We provide essential aid such as school supplies,
          technology, clothing, and food for students, along with upgraded infrastructure. This year,
          we constructed a modern library from the ground up for an elementary school of 1,200 students
          in Kabul. With the support of our members, we furnished the new library with computers,
          televisions, and a vast collection of books. Addressing the country's energy crisis,
          our organization installed solar panels at the school as an eco-friendly alternative
          to the diesel generators commonly used in Kabul. Recently, we also assisted students'
          families through the challenges of the COVID-19 pandemic. In addition to donating food
          and clothing to struggling families, we partnered with local small businesses to provide
          face masks for every student.
        </p>
      </section>

      <section>
        <h2>1. Sedaqat Educational Center</h2>
        <p>
          Managed by Ayesha Hakimi, the Sedaqat Educational Center currently has 140 students
          from first to sixth grade. Located in Dasht-e-Barchi, this center provides eight hours
          of daily lessons in subjects such as English, Math, Pashto, and basic principles
          (which include reading, writing, and arithmetic for grades one to three). The center
          employs four teachers, a manager, an assistant manager, and a guard. The monthly
          expenses range from 550 to 600 USD, covering rent, utilities, staff salaries,
          and the distribution of stationery and books to the students.
        </p>
      </section>

      <section>
        <h2>2. Action Not Word Educational Course</h2>
        <p>
          Located in the Qasaba area and managed by Walid Hoida, this course offers lessons
          in religious subjects, sewing, drawing, English, and math. The monthly expenses
          are approximately 300 USD.
        </p>
      </section>

      <section>
        <h2>3. One Hour for Afghanistan (OHA)</h2>
        <p>
          Founded by the family of Mohammad Waris Azimi and active for over six or seven years,
          One Hour for Afghanistan (OHA) serves both boys and girls. They recently established
          a sewing class for women to help them become self-sufficient. Managed by Homayoun
          Ebrahim Khail, the monthly expenses are around 400 USD.
        </p>
      </section>

      <section>
        <h2>4. Indoor Soccer Team</h2>
        <p>
          Managed by Atiq Raheen, this team is for children in grades four to six.
          They actively participate in training and tournaments, with a monthly expense of 200 USD.
        </p>
      </section>

      <section>
        <h2>Additional Support</h2>
        <p>
          Besides these activities, the foundation provides assistance to those in need during
          various occasions such as Eid or in times of natural disasters, as much as they can afford.
        </p>
      </section>
    </div>
  );
};

export default WhatWeDo;
