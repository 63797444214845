import React, { useEffect } from "react";
import Foundation from "./Foundation";
import OurTeam from "./OurTeam";
import Contributors from "./Contributors";
import AOS from "aos";
import "aos/dist/aos.css";
import "./About.css";

const About = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <h1 className="about" data-aos="fade-up">About ANW</h1>
      <Foundation />
      <hr />
      <OurTeam />
      <hr />
      <Contributors />
    </div>
  );
};

export default About;
