import React, { useState, useEffect } from "react";
import Button from "../../common/Button/Button";
import CommunityGallery from "./CommunityGallery";
import SadaqatGallery from "./SadaqatGallery";
import OneHourGallery from "./OneHourGallery";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Projects.css";

const Projects = () => {
  const [openOneHour, setOpenOneHour] = useState(false);
  const [openCommunity, setOpenCommunity] = useState(false);
  const [openSadaqat, setOpenSadaqat] = useState(false);


  useEffect(() => {
    AOS.init({ duration: 1000});
  }, [])


  const openOneHourGallery = () => {
    setOpenOneHour(true);
    setOpenCommunity(false);
    setOpenSadaqat(false);
  };

  const openCommunityGallery = () => {
    setOpenOneHour(false);
    setOpenCommunity(true);
    setOpenSadaqat(false);
  };
  const openSadaqatGallery = () => {
    setOpenOneHour(false);
    setOpenCommunity(false);
    setOpenSadaqat(true);
  };
  return (
    <div>
      
      {openOneHour && <OneHourGallery isOpen={openOneHour} setIsOpen={setOpenOneHour} />}
      {openCommunity && (
        <CommunityGallery isOpen={openCommunity} setIsOpen={setOpenCommunity} />
      )}
      {openSadaqat && (
        <SadaqatGallery isOpen={openSadaqat} setIsOpen={setOpenSadaqat} />
      )}
      <h1 className="projects"data-aos="fade-up">Projects</h1>
      <div className="projects-container">
        <div className="projects-cards-container">
          <div className="projects-card" data-aos="fade-right">
            <h2>Sedaqat Educational Center</h2>
            <p className="projects-card-description">
            Managed by Ayesha Hakimi, Sedaqat Educational Center currently has 140 students from first
             to sixth grade. Located in Dasht-e-Barchi, this center provides eight hours of daily lessons
              in subjects such as English, Math, Pashto, and basic principles (which include reading,
               writing, and arithmetic for grades one to three). The center employs four teachers,
                a manager, an assistant manager, and a guard. The monthly expenses range from 550
                 to 600 USD, covering rent, utilities, staff salaries, and the distribution of stationery
                  and books to the students.
                   </p>
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--small"
              onClick={openSadaqatGallery}
            >
              VIEW GALLERY
            </Button>
          </div>
          <div className="projects-card" data-aos="fade-left">
            <h2>Action Not Word Educational Course</h2>
            <p className="projects-card-description">
            Located in the Qasaba area and managed by Walid Hoida, this course offers lessons
             in religious subjects, sewing, drawing, English, and math. The monthly expenses are
              approximately 300 USD.
            </p>
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--small"
              onClick={openCommunityGallery}
            >
              VIEW GALLERY
            </Button>
          </div>
          <div className="projects-card" data-aos="fade-right">
            <h2>One Hour for Afghanistan (OHA)</h2>
            <p className="projects-card-description">
            Founded by the family of Mohammad Waris “Azimi” and active for over seven years,
             OHA serves both boys and girls. They recently established a sewing class for women
              to help them become self-sufficient. Managed by Homayoun Ebrahim Khail, the monthly
               expenses are around 400 USD. 
            </p>
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--small"
              onClick={openOneHourGallery}
            >
              VIEW GALLERY
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
