import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Activity.css";

import img1 from "../../images/activities/education/img (1).jpg";
import img2 from "../../images/activities/education/img (2).jpg";
import img3 from "../../images/activities/education/img (3).jpg";
import img4 from "../../images/activities/education/img (4).jpg";
import img5 from "../../images/activities/education/img (5).jpg";
import img6 from "../../images/activities/education/img (6).jpg";
import img7 from "../../images/activities/education/img (7).jpg";
import img8 from "../../images/activities/education/img (8).jpg";
import img9 from "../../images/activities/education/img (9).jpg";
import img10 from "../../images/activities/education/img (10).jpg";
import img11 from "../../images/activities/education/img (11).jpg";
import img12 from "../../images/activities/education/img (12).jpg";
import img13 from "../../images/activities/education/img (13).jpg";
import img14 from "../../images/activities/education/img (14).jpg";
import img15 from "../../images/activities/education/img (15).jpg";
import img16 from "../../images/activities/education/img (16).jpg";
import img17 from "../../images/activities/education/img (17).jpg";
import img18 from "../../images/activities/education/img (18).jpg";
import img19 from "../../images/activities/education/img (19).jpg";
import img20 from "../../images/activities/education/img (20).jpg";
import img21 from "../../images/activities/education/img (21).jpg";
import img22 from "../../images/activities/education/img (22).jpg";
import img23 from "../../images/activities/education/img (23).jpg";
import img24 from "../../images/activities/education/img (24).jpg";
import img25 from "../../images/activities/education/img (25).jpg";
import img26 from "../../images/activities/education/img (26).jpg";
import img27 from "../../images/activities/education/img (27).jpg";
import img28 from "../../images/activities/education/img (28).jpg";
import img29 from "../../images/activities/education/img (29).jpg";
import img30 from "../../images/activities/education/img (30).jpg";
import img31 from "../../images/activities/education/img (31).jpg";
import img32 from "../../images/activities/education/img (32).jpg";
import img33 from "../../images/activities/education/img (33).jpg";
import img34 from "../../images/activities/education/img (34).jpg";
import img35 from "../../images/activities/education/img (35).jpg";
import img36 from "../../images/activities/education/img (36).jpg";
import img37 from "../../images/activities/education/img (37).jpg";
import img38 from "../../images/activities/education/img (38).jpg";
import img39 from "../../images/activities/education/img (39).jpg";
import img40 from "../../images/activities/education/img (40).jpg";
import img41 from "../../images/activities/education/img (41).jpg";
import img42 from "../../images/activities/education/img (42).jpg";
import img43 from "../../images/activities/education/img (43).jpg";
import img44 from "../../images/activities/education/img (44).jpg";
import img45 from "../../images/activities/education/img (45).jpg";
import img46 from "../../images/activities/education/img (46).jpg";
import img47 from "../../images/activities/education/img (47).jpg";
import img48 from "../../images/activities/education/img (48).jpg";
import img49 from "../../images/activities/education/img (49).jpg";
import img50 from "../../images/activities/education/img (50).jpg";
import img51 from "../../images/activities/education/img (51).jpg";
import img52 from "../../images/activities/education/img (52).jpg";
import img53 from "../../images/activities/education/img (53).jpg";
import img54 from "../../images/activities/education/img (54).jpg";
import img55 from "../../images/activities/education/img (55).jpg";
import img56 from "../../images/activities/education/img (56).jpg";
import img57 from "../../images/activities/education/img (57).jpg";
import img58 from "../../images/activities/education/img (58).jpg";
import img59 from "../../images/activities/education/img (59).jpg";
import img60 from "../../images/activities/education/img (60).jpg";
import img61 from "../../images/activities/education/img (61).jpg";
import img62 from "../../images/activities/education/img (62).jpg";
import img63 from "../../images/activities/education/img (63).jpg";
import img64 from "../../images/activities/education/img (64).jpg";
import img65 from "../../images/activities/education/img (65).jpg";
import img66 from "../../images/activities/education/img (66).jpg";
import img67 from "../../images/activities/education/img (67).jpg";
import img68 from "../../images/activities/education/img (68).jpg";
import img69 from "../../images/activities/education/img (69).jpg";
import img70 from "../../images/activities/education/img (70).jpg";
import img71 from "../../images/activities/education/img (71).jpg";
import img72 from "../../images/activities/education/img (72).jpg";
import img73 from "../../images/activities/education/img (73).jpg";
import img74 from "../../images/activities/education/img (74).jpg";
import img75 from "../../images/activities/education/img (75).jpg";
import img76 from "../../images/activities/education/img (76).jpg";
import img77 from "../../images/activities/education/img (77).jpg";
import img78 from "../../images/activities/education/img (78).jpg";
import img79 from "../../images/activities/education/img (79).jpg";
import img80 from "../../images/activities/education/img (80).jpg";
import img81 from "../../images/activities/education/img (81).jpg";
import img82 from "../../images/activities/education/img (82).jpg";
import img83 from "../../images/activities/education/img (83).jpg";
import img84 from "../../images/activities/education/img (84).jpg";
import img85 from "../../images/activities/education/img (85).jpg";
import img86 from "../../images/activities/education/img (86).jpg";
import img87 from "../../images/activities/education/img (87).jpg";
import img88 from "../../images/activities/education/img (88).jpg";
import img89 from "../../images/activities/education/img (89).jpg";
import img90 from "../../images/activities/education/img (90).jpg";
import img91 from "../../images/activities/education/img (91).jpg";
import img92 from "../../images/activities/education/img (92).jpg";
import img93 from "../../images/activities/education/img (93).jpg";
import img94 from "../../images/activities/education/img (94).jpg";
import img95 from "../../images/activities/education/img (95).jpg";
import img96 from "../../images/activities/education/img (96).jpg";
import img97 from "../../images/activities/education/img (97).jpg";
import img98 from "../../images/activities/education/img (98).jpg";
import img99 from "../../images/activities/education/img (99).jpg";
import img100 from "../../images/activities/education/img (100).jpg";
import img101 from "../../images/activities/education/img (101).jpg";
import img102 from "../../images/activities/education/img (102).jpg";
import img103 from "../../images/activities/education/img (103).jpg";
import img104 from "../../images/activities/education/img (104).jpg";
import img105 from "../../images/activities/education/img (105).jpg";
import img106 from "../../images/activities/education/img (106).jpg";
import img107 from "../../images/activities/education/img (107).jpg";
import img108 from "../../images/activities/education/img (108).jpg";
import img109 from "../../images/activities/education/img (109).jpg";
import img110 from "../../images/activities/education/img (110).jpg";
import img111 from "../../images/activities/education/img (111).jpg";



const EducationGallery = ({ isOpen, setIsOpen }) => {
  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    isOpen && (
      <div className="gallery">
        <div className="gallery-modal">
          <div className="close-button" onClick={closeModal}>
            <i class="far fa-times" />
          </div>
          <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
          >
            <div>
              <img src={img1} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img2} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img3} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img4} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img5} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img6} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img7} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img8} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img9} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img10} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img11} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img12} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img13} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img14} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img15} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img16} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img17} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img18} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img19} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img20} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img21} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img22} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img23} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img24} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img25} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img26} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img27} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img28} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img29} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img30} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img31} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img32} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img33} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img34} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img35} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img36} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img37} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img38} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img39} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img40} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img41} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img42} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img43} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img44} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img45} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img46} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img47} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img48} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img49} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img50} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img51} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img52} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img53} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img54} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img55} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img56} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img57} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img58} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img59} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img60} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img61} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img62} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img63} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img64} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img65} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img66} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img67} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img68} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img69} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img70} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img71} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img72} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img73} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img74} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img75} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img76} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img77} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img78} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img79} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img80} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img81} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img82} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img83} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img84} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img85} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img86} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img87} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img88} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img89} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img90} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img91} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img92} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img93} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img94} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img95} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img96} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img97} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img98} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img99} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img100} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img101} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img102} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img103} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img104} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img105} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img106} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img107} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img108} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img109} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img110} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img111} alt="" className="carousel-image" />
            </div>

          </Carousel>
        </div>
      </div>
    )
  );
};

export default EducationGallery;
