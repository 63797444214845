import React, { useEffect } from "react";
import WhatWeDo from "./WhatWeDo";
import MakeAnImpact from "./MakeAnImpact";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Donate.css";

function Donate() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <h1 className="donate" data-aos="fade-up">Donate Today</h1>
      <MakeAnImpact />
      <hr />
      <WhatWeDo />
    </div>
  );
}

export default Donate;
