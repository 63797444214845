import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Activity.css";

import img1 from "../../images/activities/humanitarian/img (1).jpg";
import img3 from "../../images/activities/humanitarian/img (3).jpg";
import img4 from "../../images/activities/humanitarian/img (4).jpg";
import img5 from "../../images/activities/humanitarian/img (5).jpg";
import img6 from "../../images/activities/humanitarian/img (6).jpg";
import img7 from "../../images/activities/humanitarian/img (7).jpg";
import img8 from "../../images/activities/humanitarian/img (8).jpg";
import img9 from "../../images/activities/humanitarian/img (9).jpg";
import img10 from "../../images/activities/humanitarian/img (10).jpg";
import img11 from "../../images/activities/humanitarian/img (11).jpg";
import img12 from "../../images/activities/humanitarian/img (12).jpg";
import img13 from "../../images/activities/humanitarian/img (13).jpg";
import img14 from "../../images/activities/humanitarian/img (14).jpg";
import img15 from "../../images/activities/humanitarian/img (15).jpg";
import img16 from "../../images/activities/humanitarian/img (16).jpg";
import img17 from "../../images/activities/humanitarian/img (17).jpg";
import img18 from "../../images/activities/humanitarian/img (18).jpg";
import img19 from "../../images/activities/humanitarian/img (19).jpg";
import img20 from "../../images/activities/humanitarian/img (20).jpg";
import img21 from "../../images/activities/humanitarian/img (21).jpg";
import img22 from "../../images/activities/humanitarian/img (22).jpg";
import img23 from "../../images/activities/humanitarian/img (23).jpg";
import img24 from "../../images/activities/humanitarian/img (24).jpg";
import img25 from "../../images/activities/humanitarian/img (25).jpg";
import img26 from "../../images/activities/humanitarian/img (26).jpg";
import img27 from "../../images/activities/humanitarian/img (27).jpg";
import img28 from "../../images/activities/humanitarian/img (28).jpg";
import img29 from "../../images/activities/humanitarian/img (29).jpg";
import img30 from "../../images/activities/humanitarian/img (30).jpg";
import img31 from "../../images/activities/humanitarian/img (31).jpg";
import img32 from "../../images/activities/humanitarian/img (32).jpg";
import img33 from "../../images/activities/humanitarian/img (33).jpg";
import img34 from "../../images/activities/humanitarian/img (34).jpg";
import img35 from "../../images/activities/humanitarian/img (35).jpg";
import img36 from "../../images/activities/humanitarian/img (36).jpg";
import img37 from "../../images/activities/humanitarian/img (37).jpg";
import img38 from "../../images/activities/humanitarian/img (38).jpg";


const CommunityGallery = ({ isOpen, setIsOpen }) => {
  const closeModal = () => {
    setIsOpen(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [isOpen]);

  return (
    isOpen && (
      <div className="gallery">
        <div className="gallery-modal">
          <div className="close-button" onClick={closeModal}>
            <i class="far fa-times" />
          </div>
          <Carousel
            showArrows={true}
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            infiniteLoop={true}
          >
            <div>
              <img src={img1} alt="" className="carousel-image" />
            </div>
            
            <div>
              <img src={img3} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img4} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img5} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img6} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img7} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img8} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img9} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img10} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img11} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img12} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img13} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img14} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img15} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img16} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img17} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img18} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img19} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img20} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img21} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img22} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img23} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img24} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img25} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img26} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img27} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img28} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img29} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img30} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img31} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img32} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img33} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img34} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img35} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img36} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img37} alt="" className="carousel-image" />
            </div>
            <div>
              <img src={img38} alt="" className="carousel-image" />
            </div>

          </Carousel>
        </div>
      </div>
    )
  );
};

export default CommunityGallery;
