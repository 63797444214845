import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <section class="social-media">
        <div class="social-media-wrap">
          <div class="social-icons">
            <Link
              class="social-icon-link facebook"
              to="//facebook.com/groups/674888409856155/"
              target="_blank"
              aria-label="Facebook"
            >
              <i class="fab fa-facebook-f" />
            </Link>
            <Link
              class="social-icon-link instagram"
              to="//instagram.com/actionnotword/"
              target="_blank"
              aria-label="Instagram"
            >
              <i class="fab fa-instagram" />
            </Link>
            <Link
              class="social-icon-link youtube"
              to="//youtube.com/channel/UCnZVAnJHv6trhGgp9x_z-Pw"
              target="_blank"
              aria-label="Youtube"
            >
              <i class="fab fa-youtube" />
            </Link>
            <a
              class="social-icon-link email"
              href="mailto:actionnotword@gmail.com"
              target="_blank"
              rel="noreferrer"
              aria-label="Email"
            >
              <i class="fad fa-envelope" />
            </a>
          </div>
        </div>
        <small class="website-rights">© 2023 Action Not Word</small>
      </section>
    </div>
  );
};

export default Footer;
