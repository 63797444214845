import React from "react";
import ConnectWithUs from "./ConnectWithUs";
import "./ContactUs.css";

const ContactUs = () => {
  return (
    <div>
      <h1 className="contact-us">Contact Us</h1>
      <ConnectWithUs />
    </div>
  );
};

export default ContactUs;
