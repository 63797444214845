import React from "react";
import myVideo from '../../videos/promo.mp4'; // Adjust the import path

const Mission = () => {
  console.log(myVideo); // This should log the video URL
  return (
    <div className="mission" data-aos="fade-up">
      <h1>Our Mission</h1>
      <p>
        The mission of Action Not Word is to provide monetary and educational assistance to needy schools in Afghanistan. We believe the success of the country will stem from a generation of children with the opportunity to attain a quality education. Our goal is to strengthen the community by investing in local schools to provide a safe and enriching learning experience, empowering the youth of Afghanistan to achieve a better life.
      </p>
      <div>
        {/* Ensure the video is muted and plays inline to allow autoplay */}
        <video 
          width="100%" 
          height="100%" 
          autoPlay 
          muted 
          loop 
          playsInline // This allows autoplay on mobile devices as well
          style={{ border: "none" }} // Optional: styling the video element
        >
          <source src={myVideo} type="video/mp4" />
          {/* Add a fallback message if video can't be loaded */}
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default Mission;
