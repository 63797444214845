import React from "react";

const MemberCard = ({ name, position, imageURL }) => {
  return (
    <div className="card-container">
      <img className="profile-img" src={imageURL} alt="" />
      <div className="card-bottom">
        <div className="info">
          <h3>{name}</h3>
          <p>{position}</p>
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
