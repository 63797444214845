import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "./SEC.css";

import photo1 from "../../images/projects/sec/img (1).jpg";
import photo2 from "../../images/projects/sec/img (2).jpg";
import photo3 from "../../images/projects/sec/img (3).jpg";
import photo4 from "../../images/projects/sec/img (4).jpg";
import photo5 from "../../images/projects/sec/img (5).jpg";
import photo6 from "../../images/projects/sec/img (6).jpg";
import photo7 from "../../images/projects/sec/img (7).jpg";
import photo8 from "../../images/projects/sec/img (8).jpg";
import photo9 from "../../images/projects/sec/img (9).jpg";
import photo10 from "../../images/projects/sec/img (10).jpg";
import photo11 from "../../images/projects/sec/img (11).jpg";
import photo12 from "../../images/projects/sec/img (12).jpg";
import photo13 from "../../images/projects/sec/img (13).jpg";
import photo14 from "../../images/projects/sec/img (14).jpg";
import photo15 from "../../images/projects/sec/img (15).jpg";
import photo16 from "../../images/projects/sec/img (16).jpg";



function SEC() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const photos = [
    {
      src: photo1,
      alt: "Descriptive Alt Text 1",
      title: "Sadaqat Education Center (SEC)",
    },
    {
      src: photo2,
      alt: "Descriptive Alt Text 2",
      title: "Sadaqat Education Center (SEC)",
    },
    {
      src: photo3,
      alt: "Descriptive Alt Text 3",
      title: "Sadaqat Education Center (SEC)",


    },
    {
      src: photo4,
      alt: "Descriptive Alt Text 4",
      title: "Sadaqat Education Center (SEC)",
    },
    {
      src: photo5,
      alt: "Descriptive Alt Text 5",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo6,
      alt: "Descriptive Alt Text 6",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo7,
      alt: "Descriptive Alt Text 7",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo8,
      alt: "Descriptive Alt Text 8",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo9,
      alt: "Descriptive Alt Text 9",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo10,
      alt: "Descriptive Alt Text 10",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo11,
      alt: "Descriptive Alt Text 11",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo12,
      alt: "Descriptive Alt Text 12",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo13,
      alt: "Descriptive Alt Text 13",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo14,
      alt: "Descriptive Alt Text 14",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo15,
      alt: "Descriptive Alt Text 15",
      title: "Sadaqat Education Center (SEC)",

    },
    {
      src: photo16,
      alt: "Descriptive Alt Text 16",
      title: "Sadaqat Education Center (SEC)",

    },
    
  ];

  return (
    <div>
      <h1 className="sec" data-aos="fade-up">Sadaqat Education Center (SEC)</h1>
      <div className="aboutsec" data-aos="fade-right">
        <h1>Sadaqat Education Center</h1>
        <div className="donateSection" data-aos="fade-up">
          <p>
          Managed by Ayesha Hakimi, Sedaqat Educational Center currently has 140 students
           from first to sixth grade. Located in Dasht-e-Barchi, this center provides eight
            hours of daily lessons in subjects such as English, Math, Pashto, and basic principles
             (which include reading, writing, and arithmetic for grades one to three). The center
              employs four teachers, a manager, an assistant manager, and a guard. The monthly expenses
               range from 550 to 600 USD, covering rent, utilities, staff salaries, and the distribution
              of stationery and books to the students.
          </p>
        </div>
      </div>
      <hr />

      <div className="carousel-section" data-aos="fade-up">
        <h2 className="carousel-title">Our Photo Gallery</h2>
        <Carousel 
          showArrows={true} 
          showThumbs={false} 
          infiniteLoop={true} 
          autoPlay={true} 
          interval={3000} 
          transitionTime={1000} 
          showStatus={false}
        >
          {photos.map((photo, index) => (
            <div className="photo-section" key={index}>
              <h2 className="photo-title">{photo.title}</h2>
              <p className="photo-text">{photo.description}</p>
              <img src={photo.src} alt={photo.alt} className="photo" />
            </div>
          ))}
        </Carousel>
      </div>
      
      <div className="whatWeDo" data-aos="fade-left">
        <h1>What Are We Doing?</h1>
        <p>
          <strong>Our Mission:</strong> Empowering Through Education <br/>
          At One Hour for Afghanistan, our mission is to create educational opportunities for children in Afghanistan who might otherwise be deprived of learning. We provide:<br/>
          <strong>• Free Educational Programs:</strong> Offering a wide range of subjects, from basic literacy and numeracy to more advanced topics.<br/>
          <strong>• Volunteer-Led Classes:</strong> Conducted by dedicated volunteers who are passionate about teaching and making a difference.<br/>
          <strong>• Community Engagement:</strong> Encouraging community involvement and support to create a sustainable educational environment.<br/>
          <strong>• Support and Resources:</strong> Providing necessary educational materials and resources to ensure effective learning experiences.<br/>
          Our efforts aim to nurture the potential of every child, helping them to build a brighter future through the power of education.
        </p>
      </div>
    </div>
  );
}

export default SEC;
