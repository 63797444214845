import React from "react";
import Button from "../../common/Button/Button";

const MakeAnImpact = () => {
  return (
    <div className="makeAnImpact" data-aos="fade-right">
      <h1>Make an Impact! <br/>
        Donate Now</h1>
      <div className="donateSection" data-aos="fade-up">
        <p>
        Thanks to the generosity of our regular members and donors, we have raised over $120,000 since 2020. Contributions of any size make a difference, significantly improving the schools and lives of the communities that greatly need our help
        </p>
        <div className="hero-btns">
          <div className="hero-btn-group">
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--large"
              src="https://portal.icheckgateway.com/Gen/Default.aspx?P=af69551cfe82491697dd"
              newTab="true"
            >
              UNITED STATES
            </Button>
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--large"
              src="https://www.paypal.com/paypalme/atiqrahin"
              newTab="true"
            >
              INTERNATIONAL
            </Button>
          </div>
          <p style={{ marginTop: "6px" }}>Zelle: actionnotword@gmail.com</p>
          <p>Venmo: @action-notword</p>
        </div>
      </div>
    </div>
  );
};

export default MakeAnImpact;
