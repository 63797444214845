const ContributorData = {

    "2023": [
      "Abas Afshar",
      "Abdul Saleem Hekmati",
      "Abdullah Ali",
      "Abdullah Monawar",
      "Adela Ackiczai",
      "Adham Hakimi",
      "Aisha Hakimi",
      "Ali esmailzada",
      "Asad Nawabi",
      "Atifa Rawan",
      "Atiq Rahin",
      "Captain Bashir",
      "Dr Abdullah Nawabi",
      "David",
      "Dr Yahya Dostzada",
      "Duran Gardezi",
      "Easa Arzo",
      "Edris Ebrahimkhail",
      "Engineer Kasem Gardezi",
      "Engineer Naseem Yar",
      "Fahim Khater",
      "Farooq Sidiq",
      "Haji Asad Wali",
      "Haji Karim Jawahiri",
      "Hamayon Ibrahimkhail",
      "Hamed Rahin",
      "Hasena Nasraty",
      "Jahid Ahmad",
      "Kabir Lutfi",
      "Kabir Rafiq",
      "Kamila",
      "Karima Aslami",
      "Mahbooba khaliqi",
      "Maria Danishwar",
      "Shoja Alokozai",
      "Mariam Frotan",
      "Mohamad Karimi",
      "Meenah Ibrahimkhail",
      "Kamran Faizi",
      "Mariam yonous",
      "MARIAM Sakha",
      "Nadia & Shiela Rahin",
      "Lisa Dais",
      "Najaf Yousofi",
      "Rahmat Adel",
      "Reza Mohammady",
      "Sabir Sarwari",
      "Sadaf Khalid",
      "Sabeeha Rahin",
      "U/N for Atiq's BD",
      "Salima Kabir",
      "Said Masood Maudoodi",
      "Serous Hasani",
      "Shaheena Raoufi",
      "Shahir Erfan",
      "Sharif Azami",
      "Sharif Khauja",
      "Sohaila Rahin Akhger",
      "Solaiman Akhgar",
      "Solaiman Rafiq",
      "Hajira Nori",
      "Tamim Nawabi",
      "Tawfiq Azami",
      "Liza Dais",
      "Timor Nasseri",
      "Wahid Lutfi",
      "Wajid Mansoori",
      "Rezwana Wahdat",
      "Mr Karimi sahib",
      "Shiela Rahin",
      "Zainab Rahin",
      "Sahar Akhgar",
      "Sadaf Akhgar",
      "Anonymous",
      "Sidiq Farooq",
      "Dina Esmaielzadah",
      "Kabir Sultani",
      "Freshta Taeb",
      "Najwa Khater",
      "Sofia Wahdat",
      "Haroon Ibrahimkhail",
      "Freshta Rahin",
      "Parveen Michalow"
    ],
  
  2022: [
    "Abas Afshar",
    "Abdul Saleem Hekmati",
    "Abdullah Ali",
    "Adela Acheckzai (client)",
    "Adela Ackiczai",
    "Aisha Hakimi",
    "Ali esmailzada",
    "Ali Maisam Rahin",
    "Ali Sina Rahin",
    "Andi st. Clare",
    "Atifa Rawan",
    "Atiq Rahin",
    "Atiq Rahin pay back",
    "Captain Bashir",
    "Dr Stefan Schneider",
    "Dr. Yahya Dostzada",
    "Easa Arzo",
    "Edris Ebrahimkhail",
    "Engineer Kasem Gardezi",
    "Engineer Naseem Yar",
    "Engineer Parween & Tahir",
    "Farooq Sidiq",
    "Haji Karim Jawahiri",
    "Hakim Hasani",
    "Hamayon Ibrahimkhail",
    "Hasena Nasraty",
    "Kabir Lutfi",
    "Kabir Rafiq",
    "Kamila",
    "Karima Aslami",
    "Maria Danishwar",
    "Mohammed Sharif Azami",
    "Muzdah Achekzai",
    "Rahin's donations",
    "Rahmat Adel",
    "Reza Mohammady",
    "Sabeeha Rahin",
    "Sabirah Achekzai",
    "Sadaf Achekzai",
    "Sadaf Khalid",
    "Sadaf & Muzdah Achickzai",
    "Safia Ibrahimkhail",
    "Said Masood Maudoodi",
    "Serous Hasani",
    "Shafiq Rahman Saifi",
    "Shaheena Raoufi",
    "Shahir Erfan",
    "Shokoor Raji",
    "Soccer charity",
    "Sohaila Rahin",
    "Tamim Nawabi",
    "Tawfiq Azami",
    "Timor Nasseri",
    "Waheed Yonos",
    "Wahid Rahin",
    "Wajid Mansoori",
    "Wann Abawi maiwad",
    "Zainab Rahin",
  ],
  2021: [
    "Abdul Saleem Hekmati",
    "Abdul Zahid Jahid",
    "Abdullah Ali",
    "Abdullah Monawar",
    "Adela Ackiczai",
    "Ahmad Abawi",
    "Ahmad Ayub",
    "Ahmad Hamid",
    "Aisha Hakimi",
    "Ali Ismaielzada",
    "Ali Maisam Rahin",
    "Ali Sina Rahin",
    "Asad Nawabi",
    "Asad Nawabi",
    "Atifa Rawan",
    "Atiq Rahin",
    "Aziz Ghani",
    "Basel Noristani",
    "Captain Bashir",
    "Dr Abdullah Nawabi",
    "Dr Reza Danish",
    "Dr Yahya Dostzada",
    "Edris Ebrahimkhyl",
    "Emma Miller",
    "Engineer Kasem Gardezi",
    "Engineer Naseem Yar",
    "Engineer Parween & Tahir",
    "Fahim Khater",
    "Farooq Sidiq",
    "Habib Saha",
    "Hafiz Haidari",
    "Haji Asad Wali",
    "Haji Karim Jawahiri",
    "Haji Yonos Wahdat",
    "Hamayon Ibrahimkhail",
    "Hamed Rahin",
    "Hanifa Habib",
    "Jahid Ahmad",
    "Kabir Lutfi",
    "Kabir Rafiq",
    "Madeleine Rafter",
    "Maria Danishwar",
    "Mariam Hashimi",
    "Mariam Sakha",
    "Masood Popal",
    "Meenah Ibrahimkhail",
    "Mujdah Sarwari",
    "Nadia & Shiela Rahin",
    "Nadia Delawar",
    "Nadir Omar",
    "Najib Latif",
    "Rahmat Adel",
    "Rahmatullah Ahmadzai",
    "Raouf Mahmood",
    "Reza Mohammady",
    "Rohiya Latif",
    "Sadulah Ahmadzai",
    "Safaa Karoumia",
    "Said Masood Maudoodi",
    "Serous Hasani",
    "Shafiq Rahman Saifi",
    "Shaheena Raoufi",
    "Shahir Erfan",
    "Sharif Azami",
    "Shoja Alokozai",
    "Shokoor Raji",
    "Sohaila Rahin",
    "Solaiman Akhgar",
    "Soraya Omar",
    "Sosan Wali",
    "Tamim Nawabi",
    "Tawfiq Azami",
    "Timor Nasseri",
    "Wajid Mansoori",
    "Walid Nandi & Nargis Latifi",
    "Zabi Rahin",
    "Zahra Sarwari",
    "Zainab Rahin",
  ],
};

export default ContributorData;
