import React, { useState, useEffect } from "react";
import Button from "../../common/Button/Button";
import EducationGallery from "./EducationGallery";
import WorkGallery from "./WorkGallery";
import CommunityGallery from "./CommunityGallery";
import SoccerGallery from "./SoccerGallery";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Activity.css";


const Activity = () => {
  const [openEducation, setOpenEducation] = useState(false);
  const [openWork, setOpenWork] = useState(false);
  const [openCommunity, setOpenCommunity] = useState(false);
  const [openSoccer, setOpenSoccer] = useState(false);


  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const openEducationGallery = () => {
    setOpenEducation(true);
    setOpenWork(false);
    setOpenCommunity(false);
    setOpenSoccer(false);
  };

  const openWorkGallery = () => {
    setOpenEducation(false);
    setOpenWork(true);
    setOpenCommunity(false);
    setOpenSoccer(false);
  };

  const openCommunityGallery = () => {
    setOpenEducation(false);
    setOpenWork(false);
    setOpenCommunity(true);
    setOpenSoccer(false);
  };
  const openSoccerGallery = () => {
    setOpenEducation(false);
    setOpenWork(false);
    setOpenCommunity(false);
    setOpenSoccer(true);
  };

  return (
    <div>
      {openEducation && (
        <EducationGallery isOpen={openEducation} setIsOpen={setOpenEducation} />
      )}
      {openWork && <WorkGallery isOpen={openWork} setIsOpen={setOpenWork} />}
      {openCommunity && (
        <CommunityGallery isOpen={openCommunity} setIsOpen={setOpenCommunity} />
      )}
      {openSoccer && (
        <SoccerGallery isOpen={openSoccer} setIsOpen={setOpenSoccer} />
      )}
      <h1 className="activity" data-aos="fade-up">What We Do</h1>
      <div className="activity-container">
        <div className="activity-cards-container">
          <div className="activity-card" data-aos="fade-right">
            <h2>Education</h2>
            <p className="activity-card-description">
              We provide educational assistance by supplying schools with
              supplies, technology, clothing and food for students, maintaining
              teacher/staff salaries, as well as upgrading school
              infrastructure. Last year, we built a modern library from scratch
              for an elementary school of 1200 students in Kabul. With our
              members' help, we were able to equip the brand new library with
              computers, televisions, and a large number of books.
            </p>
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--small"
              onClick={openEducationGallery}
            >
              VIEW GALLERY
            </Button>
          </div>
          <div className="activity-card" data-aos="fade-left">
            <h2>Work Program</h2>
            <p className="activity-card-description">
              As part of our work program we hired students' families to repair
              and paint classrooms, as well as bake pastries/cookies to be sold
              as a source of income for the students' families and to address
              high levels of unemployment. Thus, students will not be obligated
              to work after school to help the family financially.
            </p>
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--small"
              onClick={openWorkGallery}
            >
              VIEW GALLERY
            </Button>
          </div>
          <div className="activity-card" data-aos="fade-right">
            <h2>Indoor Soccer Team</h2>
            <p className="activity-card-description">
            Managed by Atiq Raheen, this team is for children in grades four to six. They actively
             participate in training and tournaments, with a monthly expense of 200 USD.
            </p>
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--small"
              onClick={openSoccerGallery}
            >
              VIEW GALLERY
            </Button>
          </div>
          <div className="activity-card" data-aos="fade-left">
            <h2>Humanitarian Aid</h2>
            <p className="activity-card-description">
              As part of our humanitarian assistance efforts we started helping
              some students' families through the struggles brought forth by the
              COVID-19 pandemic. In addition to donating food and clothing to
              families who were hurting, we partnered with local small
              businesses to provide face masks for each and every student.
            </p>
            <Button
              className="btns"
              buttonStyle="btn--solid"
              buttonSize="btn--small"
              onClick={openCommunityGallery}
            >
              VIEW GALLERY
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activity;
