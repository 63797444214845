import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import "./OHA.css";

import myVideo from '../../videos/ohastart.mp4'; // Adjust the import path
import additionalVideo1 from '../../videos/OHAintro.mp4'; // Example additional video
import additionalVideo2 from '../../videos/OHA4Years.mp4'; // Example additional video

import photo1 from "../../images/oha/img (1).jpg";
import photo2 from "../../images/oha/img (2).jpg";
import photo3 from "../../images/oha/img (3).jpg";
import photo4 from "../../images/oha/img (4).jpg";
import photo5 from "../../images/oha/img (5).jpg";
import photo6 from "../../images/oha/img (6).jpg";
import photo7 from "../../images/oha/img (7).jpg";
import photo8 from "../../images/oha/img (8).jpg";
import photo9 from "../../images/oha/img (9).jpg";
import photo10 from "../../images/oha/img (10).jpg";
import photo11 from "../../images/oha/img (11).jpg";
import photo12 from "../../images/oha/img (12).jpg";
import photo13 from "../../images/oha/img (13).jpg";
import photo14 from "../../images/oha/img (14).jpg";
import photo15 from "../../images/oha/img (15).jpg";
import photo16 from "../../images/oha/img (16).jpg";
import photo17 from "../../images/oha/img (17).jpg";
import photo18 from "../../images/oha/img (18).jpg";
import photo19 from "../../images/oha/img (19).jpg";

function OHA() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const photos = [
    {
      src: photo1,
      alt: "Descriptive Alt Text 1",
      title: "One Hour for Afghanistan (OHA)",
      description: "Books, Stationary and bags distributions for OHA program."
    },
    {
      src: photo2,
      alt: "Descriptive Alt Text 2",
      title: "One Hour for Afghanistan (OHA)",
      description: "OHA new class look after reconstruction."
    },
    {
      src: photo3,
      alt: "Descriptive Alt Text 3",
      title: "One Hour for Afghanistan (OHA)",
      description: "OHA new class look after reconstruction."
    },
    {
      src: photo4,
      alt: "Descriptive Alt Text 4",
      title: "One Hour for Afghanistan (OHA)",
      description: "Student seminar competition closing program."
    },
    {
      src: photo5,
      alt: "Descriptive Alt Text 5",
      title: "New Centre opening",
      description: "We have opened a new centre in Kabul, Afghanistan to provide free education for more children."
    },
    {
      src: photo6,
      alt: "Descriptive Alt Text 6",
      title: "New Centre opening",
      description: "We have opened a new centre in Kabul, Afghanistan to provide free education for more children."
    },
    {
      src: photo7,
      alt: "Descriptive Alt Text 7",
      title: "One Hour for Afghanistan (OHA)",
      description: "Establishing new computer class."
    },
    {
      src: photo8,
      alt: "Descriptive Alt Text 8",
      title: "One Hour for Afghanistan (OHA)",
      description: "Establishing new computer class."
    },
    {
      src: photo9,
      alt: "Descriptive Alt Text 9",
      title: "New Centre opening",
      description: "We have opened a new centre in Kabul, Afghanistan to provide free education for more children."
    },
    {
      src: photo10,
      alt: "Descriptive Alt Text 10",
      title: "New Centre opening",
      description: "We have opened a new centre in Kabul, Afghanistan to provide free education for more children."
    },
    {
      src: photo11,
      alt: "Descriptive Alt Text 11",
      title: "Ramadan Programs",
      description: "Commemoration of holy Quran at the month of Ramadan."
    },
    {
      src: photo12,
      alt: "Descriptive Alt Text 12",
      title: "Monthly Exams",
      description: "Students are attending monthly exams."
    },
    {
      src: photo13,
      alt: "Descriptive Alt Text 13",
      title: "Educational tour",
      description: "Kabul Zoo exploration from students and teachers of OHA."
    },
    {
      src: photo14,
      alt: "Descriptive Alt Text 14",
      title: "Educational tour",
      description: "Kabul Zoo exploration from students and teachers of OHA."
    },
    {
      src: photo15,
      alt: "Descriptive Alt Text 15",
      title: "Motivational speeches",
      description: "Mohammad Waris Azimi, Founder of OHA, gives speech to students."
    },
    {
      src: photo16,
      alt: "Descriptive Alt Text 16",
      title: "Prize Distribution",
      description: "Best essay competition closing program and prize distribution."
    },
    {
      src: photo17,
      alt: "Descriptive Alt Text 17",
      title: "New Centre opening",
      description: "We have opened a new centre in Kabul, Afghanistan to provide free education for more children."
    },
    {
      src: photo18,
      alt: "Descriptive Alt Text 18",
      title: "Aids Distribution",
      description: "By the help of ANW we have distributed stationary and bags for all the 300 children."
    },
    {
      src: photo19,
      alt: "Descriptive Alt Text 19",
      title: "Aids Distribution",
      description: "By the help of ANW we have distributed stationary and bags for all the 300 children."
    }
  ];

  return (
    <div>
      <h1 className="oha" data-aos="fade-up">One Hour For Afghanistan (OHA)</h1>
      <div className="aboutoha" data-aos="fade-right">
        <h1>One Hour For Afghanistan</h1>
        <div className="donateSection" data-aos="fade-up">
          <p>
            Founded by the family of Mohammad Waris Azimi in April 2019, One Hour for Afghanistan (OHA)
            is a volunteer-driven NGO dedicated to providing free education for children in Afghanistan.
            Managed by Homayoun Ebrahim Khail, OHA serves both boys and girls and recently established
            a sewing class for women to help them become self-sufficient. With monthly expenses around
            400 USD, our initiative began with a passionate group of young volunteers who sought to
            make a positive impact in the lives of Afghan children through education. Since our inception,
            we have been committed to empowering the next generation by offering educational programs that
            foster learning and growth, all at no cost to the families we serve.
          </p>
        </div>
      </div>
      <hr />
      
      <div className="video-section" data-aos="fade-up">
        <h2 className="video-title">How OHA Started?</h2>
        <div className="video-container">
          <video width="80%" height="auto" controls data-aos="fade-up">
            <source src={myVideo} type="video/mp4" />
          </video>
          <h2 className="video-title">ANW & OHA First Collaboration</h2>
          <video width="80%" height="auto" controls data-aos="fade-up">
            <source src={additionalVideo1} type="video/mp4" />
          </video>
          <h2 className="video-title">Impact of ANW on OHA</h2>
          <video width="80%" height="auto" controls data-aos="fade-up">
            <source src={additionalVideo2} type="video/mp4" />
          </video>
        </div>
      </div>

      <div className="carousel-section" data-aos="fade-up">
        <h2 className="carousel-title">Our Photo Gallery</h2>
        <Carousel 
          showArrows={true} 
          showThumbs={false} 
          infiniteLoop={true} 
          autoPlay={true} 
          interval={3000} 
          transitionTime={1000} 
          showStatus={false}
        >
          {photos.map((photo, index) => (
            <div className="photo-section" key={index}>
              <h2 className="photo-title">{photo.title}</h2>
              <p className="photo-text">{photo.description}</p>
              <img src={photo.src} alt={photo.alt} className="photo" />
            </div>
          ))}
        </Carousel>
      </div>
      
      <div className="whatWeDo" data-aos="fade-left">
        <h1>What Are We Doing?</h1>
        <p>
          <strong>Our Mission:</strong> Empowering Through Education <br/>
          At One Hour for Afghanistan, our mission is to create educational opportunities for children in Afghanistan who might otherwise be deprived of learning. We provide:<br/>
          <strong>• Free Educational Programs:</strong> Offering a wide range of subjects, from basic literacy and numeracy to more advanced topics.<br/>
          <strong>• Volunteer-Led Classes:</strong> Conducted by dedicated volunteers who are passionate about teaching and making a difference.<br/>
          <strong>• Community Engagement:</strong> Encouraging community involvement and support to create a sustainable educational environment.<br/>
          <strong>• Support and Resources:</strong> Providing necessary educational materials and resources to ensure effective learning experiences.<br/>
          Our efforts aim to nurture the potential of every child, helping them to build a brighter future through the power of education.
        </p>
      </div>
    </div>
  );
}

export default OHA;
